/** @format */

import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { useNavigate } from "react-router-dom";
import {
	GetSalesCountByMonth,
	GetUserCount,
	GetServiceCount,
	GetDriverCount,
	GetMostBookedService,
} from "../../services/Api/Api";
import {
	GetAllBookingCount,
	GetCompletedCount,
	GetDeletedCount,
	GetPendingCount,
} from "../../services/Api/BookingApi";
import "./Dashboard.css";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { BsFillClockFill } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { BsTagsFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
const getAllMonths = () => {
	const months = [
		"Jan",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	return months.map((month) => ({ type: month, sales: 0 }));
};

const Dashboard = () => {
	const navigate = useNavigate();
	const [salesCount, setSalesCount] = useState([]);
	const [driverData, setDriverData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [mostBookedService, setMostBookedService] = useState([]);
	const [bookingCount, setBookingCount] = useState([]);
	const [pendingCount, setPendingCount] = useState([]);
	const [completedCount, setCompletedCount] = useState([]);
	const [deletedCount, setDeletedCount] = useState([]);

	ChartJS.register(ArcElement, Tooltip, Legend);
	const data = {
		labels: mostBookedService.map((item) => item.service_name || ""),

		datasets: [
			{
				data: mostBookedService.map((item) => item.booking_count || 0),
				backgroundColor: ["#798BFF", "#B8ACFF", "#FFA9CE", "#F9DB7C"],
				borderColor: ["#798BFF", "#B8ACFF", "#FFA9CE", "#F9DB7C"],
				borderWidth: 1,
			},
		],
	};

	mostBookedService.forEach((item) => {
		console.log("Service Booking Name:", item.service_id);
	});

	//get customer count
	const getMostBookedService = async () => {
		try {
			const result = await GetMostBookedService(
				localStorage.getItem("adminToken")
			);
			setMostBookedService(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
		}
	};

	//get customer count
	const getCustomerData = async () => {
		try {
			const result = await GetUserCount(localStorage.getItem("adminToken"));
			setCustomerData(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
		}
	};

	//get customer count
	const getDriverData = async () => {
		try {
			const result = await GetDriverCount(localStorage.getItem("adminToken"));
			setDriverData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	//get likes count
	const getServiceCount = async () => {
		try {
			const result = await GetServiceCount(localStorage.getItem("adminToken"));
			setServiceData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	//get usercount by month api
	const getData = async () => {
		try {
			const result = await GetSalesCountByMonth(
				localStorage.getItem("adminToken")
			);
			setSalesCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getBookingCount = async () => {
		try {
			const result = await GetAllBookingCount(
				localStorage.getItem("adminToken")
			);
			setBookingCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getPendingBookingCount = async () => {
		try {
			const result = await GetPendingCount(localStorage.getItem("adminToken"));
			setPendingCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getCompletedBookingCount = async () => {
		try {
			const result = await GetCompletedCount(
				localStorage.getItem("adminToken")
			);
			setCompletedCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getDeletedBookingCount = async () => {
		try {
			const result = await GetDeletedCount(localStorage.getItem("adminToken"));
			setDeletedCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getData();
		getServiceCount();
		getDriverData();
		getCustomerData();
		getMostBookedService();
		getBookingCount();
		getDeletedBookingCount();
		getCompletedBookingCount();
		getPendingBookingCount();
	}, []);

	const formattedData =
		salesCount.length > 0
			? salesCount.map(({ month, userCount }) => ({
					type: month,
					sales: userCount,
			  }))
			: getAllMonths();

	const config = {
		data: formattedData,
		xField: "type",
		yField: "sales",
		color: ({ type }) => "#C5ABFF",
		label: {
			position: "middle",
			style: {
				fill: "#FFFFFF",
				opacity: 1,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		meta: {
			type: {
				alias: "Month",
			},
			sales: {
				alias: "User Count",
			},
		},
	};

	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					marginBottom: "20PX",
				}}
			>
				<h3 className="dashboard_title">Dashboard</h3>
				<p className="page-sub-title" style={{ color: "green" }}>
					Welcome to Web Admin Panel!
				</p>
			</div>

			<div className="d-flex justify-content-between align-items-center gap-3 mb-3">
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3"
					style={{ borderRight: "5px solid #1677FF" }}
				>
					<div>
						<p className="desc">Total Users</p>
						<h4 className="mb-0 sub-title">{customerData}</h4>
					</div>
				</div>
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3"
					style={{ borderRight: "5px solid #1677FF" }}
				>
					<div>
						<p className="desc">Total Employees</p>
						<h4 className="mb-0 sub-title">{driverData}</h4>
					</div>
				</div>

				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3"
					style={{ borderRight: "5px solid #1677FF" }}
				>
					<div>
						<p className="desc">Total Services</p>
						<h4 className="mb-0 sub-title">{serviceData}</h4>
					</div>
				</div>
			</div>

			<div style={{ display: "flex" ,justifyContent: "space-between"}}>

				{/* chart */}
				<div style={{width: "40%" }}>
					
					<div className="right_graph">
						<h3 className="mb-5 title" style={{ marginBottom: "20px" }}>
							Booking Overview
						</h3>
						<Doughnut data={data} />
					</div>
				</div>

				{/* booking data */}
				<div style={{ display: "flex", flexDirection: "column", width: "70%",marginTop:"10px",marginLeft:"20px" }}>

				<div className="d-flex justify-content-between align-items-center gap-3 mb-3">
					<div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"  style={{height:"115px", borderBottom: "5px solid #B8ACFF" }}>
						<div>
							<p className="desc">Total Bookings:</p>
							<h4 className="mb-0 sub-title">{bookingCount}</h4>
						</div>
						<div className="d-flex flex-column align-items-end">
							<h6 className="red">
								<BsTagsFill style={{ fontSize: "40px" }} />
							</h6>
							<p className="mb-0 desc"></p>
						</div>
					</div>
					<div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"  style={{height:"115px", borderBottom: "5px solid #B8ACFF"}}>
						<div>
							<p className="desc">Completed Bookings:</p>
							<h4 className="mb-0 sub-title">{completedCount}</h4>
						</div>
						<div className="d-flex flex-column align-items-end">
							<h6 className="red">
								<FaCheckCircle style={{ fontSize: "40px" }} />
							</h6>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-between align-items-center gap-3 mb-3">
					
					<div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"  style={{height:"115px", borderBottom: "5px solid #B8ACFF" }}>
						<div>
							<p className="desc">Pending Bookings:</p>
							<h4 className="mb-0 sub-title">{pendingCount}</h4>
						</div>
						<div className="d-flex flex-column align-items-end">
							<h6 className="red">
								<BsFillClockFill style={{ fontSize: "40px" }} />
							</h6>
						</div>
					</div>

					<div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"  style={{height:"115px", borderBottom: "5px solid #B8ACFF"}}>
						<div>
							<p className="desc">Deleted Bookings:</p>
							<h4 className="mb-0 sub-title">{deletedCount}</h4>
						</div>
						<div className="d-flex flex-column align-items-end">
							<h6 className="red">
								<MdDeleteForever style={{ fontSize: "40px" }} />
							</h6>
						</div>
					</div>
				</div>

				</div>
			</div>
		</div>
	);
};

export default Dashboard;
